<template>
	<div class="readme-article">
		<h1 id="一对一-一对二">
			三、一对一 一对二
		</h1>
		<h2 id="按周排课">1.按周排课</h2>
		<h3 id="学员排课列表">1.1学员排课列表</h3>
		<p>查看学员列表，显示学员余额，剩余课时等信息，可针对学员进行排课，查看课程表。</p>
		<p>
			<img src="@/assets/img/teach/3-1.png">
		</p>
		<h3 id="排课">1.1排课</h3>
		<p>管理员可对学员进行排课，按星期和时间进行选择，选择开课时间和结束时间等信息完成排课。</p>
		<p>
			<img src="@/assets/img/teach/3-2.png">
		</p>
		<h3 id="课程表">1.2课程表</h3>
		<p>学员课程表，查看学员从今日起后一周的排课情况。</p>
		<p>
			<img src="@/assets/img/teach/3-3.png">
		</p>
		<h2 id="按周排课-新">2.按周排课(新)</h2>
		<p>管理员可对学员进行批量排课，选择多名学员，一名教师并填写相关信息完成排课。</p>
		<p>
			<img src="@/assets/img/teach/3-4.png">
		</p>
		<h2 id="按日期排课">3.按日期排课</h2>
		<p>管理员可按日期给学员排课，选择教师、上课日期、上课时间、科目、教室、学员完成排课。</p>
		<p>
			<img src="@/assets/img/teach/3-5.png">
		</p>
		<h2 id="排课单打印">4.排课单打印</h2>
		<p>系统可打印排课单，可通过日期，时间进行筛选。</p>
		<p>
			<img src="@/assets/img/teach/3-6.png">
		</p>
	</div>
</template>

<script>
	export default {
		name: "Staff3-1",
		data() {
			return {};
		},
		mounted() {},
		methods: {},
	};
</script>
<style scoped>
</style>
